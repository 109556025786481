import React, { useState } from 'react';
import WorkExperience from './components/Experience';
import Skill from './components/Skill';
import Project from './components/Project';
import ProfilePic from './images/yuiko.png';
import GithubIcon from './images/github.svg';
import LinkedinIcon from './images/linkedin.svg';
import ResumeIcon from './images/resume.svg';
import EmailIcon from './images/email.svg';
import ContactForm from './components/ContactForm';
import Header, { agendas } from './components/Header';
import Article from './components/ Article';

const Top = (
  <div className='px-20 py-12 grid gap-16 bg-gray-900'>
    <section className='grid grid-cols-2 gap-20'>
      <div className='grid gap-4'>
        <h1 className='text-5xl leading-tight font-semibold'>Hey there, <br/>I'm Yuiko Nishimura<br/>Full-Stack Engineer</h1>
        <p>
          Experienced Full-stack engineer skilled in requirement definition, development, testing, and release processes.
          <br/>
          <br/>
          Proficient in various platforms and languages. <br/>
          Gained valuable experience working as a team lead, implementing changes to boost team productivity and efficiency.
        </p>

      </div>
      <div className="flex justify-center items-center">
        <img src={ProfilePic} alt="Profile Pic" className="rounded-full square-image" />
      </div>
    </section>

    {agendas.map((agenda) => {
      switch(agenda['name']) {
        case 'Home':
          return null
        case 'Experiences':
          return <WorkExperience/>
        case 'Skills':
          return <Skill/>
        case 'Projects':
          return <Project/>
        case 'Articles':
          return <Article/>
        default:
          return (
              <section id="projects" className='' key={agenda['name']}>
                  <div className=''>
                    <h1 className='text-4xl leading-tight font-semibold mb-4'>{agenda['name']}</h1>
                    <p>test</p>
                  </div>
              </section>
              )
      }
      })}
  </div>
)


const App: React.FC = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  return (
  <div className=' text-white w-screen'>
  <div className='flex flex-row '>
    <div className="fixed flex items-center justify-center h-screen">
      <div className="m-auto p-6 gap-3 flex-col flex">
        <a href="https://github.com/YUIKO-NISHIMURA" target="_blank" rel="noopener noreferrer">
        <img
            className="-rotate-90"
            alt="Github"
            src={GithubIcon}
            width={20}
            height={20}
        />
        </a>
        <a href="https://www.linkedin.com/in/yuiko-nishimura/" target="_blank" rel="noopener noreferrer">
        <img
            alt="LinkedIn"
            className="-rotate-90"
            src={LinkedinIcon}
            width={20}
            height={20}
        />
        </a>
        <a href="https://drive.google.com/uc?id=1Z_LVzysGhLQEVdP10jq5CBo2y-E4JGLb" target="_blank" rel="noopener noreferrer">
        <img
            alt="Resume"
            className="-rotate-90"
            src={ResumeIcon}
            width={20}
            height={20}
        />
        </a>
        <button 
        onClick={() => setShowContactForm(true)}
        >
        <img
            alt="Email"
            className="-rotate-90"
            src={EmailIcon}
            width={20}
            height={20}
        />
        </button>
      </div>
    </div>
    <div className="ml-8 w-full">
      <Header showContactForm={setShowContactForm}></Header>
      {showContactForm ? 
      <ContactForm/> : Top}
    </div>
  </div>
  </div>
  )
}

export default App;
