import React from 'react';

interface WorkExperienceData {
  companyName: string;
  term: string;
  position: string;
  description: string;
}

const WorkExperience: React.FC = () => {
  const workExperienceData: WorkExperienceData[] = [
    {
      companyName: 'ActiValues, Inc.',
      term: 'October 2021 - Present',
      position: 'Full-Stack Enginner',
      description:
        `
        Full-Stack Enginner specializing in React.js, TypeScript.
        </br>
        </br>
        - Developed reusable and customizable UI web components and implemented corresponding REST APIs.
        </br>
        - Introduced company’s first-ever React front-end tests using Jest and React Testing Library.
        </br>
        - Resolved various website issues and bugs, resulting in improved website functionality and user experience.
        </br>
        - Collaborated with designers and sales members to take full responsibility 
        <br/>for the refinement, development and delivery of a new feature as a product manager.
        </br>
        - Improved team efficiency and productivity by introducing company’s first-ever Scrum methodology.
        `
    },
    {
      companyName: 'TOTO, Inc.',
      term: 'April 2019 - September 2021',
      position: 'Agency Sales Specialist',
      description:
        `
        - Attained 140% sales quota</br>
        - Top sales performer out of 20
`,
    },
    // Add more work experience entries as needed
  ];

  return (
    <section id='Experiences'>
      <h2 className="text-4xl leading-tight font-semibold mb-4 border-b-4 border-y-green w-fit ">Experiences</h2>
      <div>
        {workExperienceData.map((experience, index) => (
          <div key={index} className="flex flex-row">
            <div className="shrink-0 basis-1/4">
              <div className="text-xl font-bold mb-2">
                {experience.companyName}
              </div>
              <div className="">{experience.term}</div>
            </div>
            <div className="shrink-0 mr-8">
              <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
              <div className="mt-[-2px] w-0.5 h-56 bg-gray-500 ml-[0.39rem]"></div>
            </div>
            <div className="shrink-0">
              <div className="text-xl font-bold mb-2">
                {experience.position}
              </div>
              <div className="" dangerouslySetInnerHTML={{ __html: experience.description }} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WorkExperience;
