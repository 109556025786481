// ContactForm.tsx

import React, { useState } from 'react';
import Header from './Header';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const ContactForm: React.FC = () => {
const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      const handleSubmit = async () => {
        try {
          // Replace 'YOUR_FORMSPREE_ENDPOINT' with the actual endpoint provided by Formspree
          const response = await fetch('https://formspree.io/f/xjvqdayn', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
      
          if (response.ok) {
            alert('Message sent successfully!');
            setFormData({ name: '', email: '', message: '' });
          } else {
            alert('Failed to send message. Please try again later.');
          }
        } catch (error) {
          console.error('Error sending message:', error);
          alert('Failed to send message. Please try again later.');
        }
      };

  return (
    <div className="mx-60 mt-12 p-6 rounded-lg">
      <form>
        <div className="mb-4 flex">
          <div className="flex-1 mr-4">
            <label className="block text-sm font-bold mb-2" htmlFor="name">
              Name:
            </label>
            <input
              className="text-black w-full px-3 py-2 border rounded-md focus:outline-none focus:border-green-500"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-bold mb-2" htmlFor="email">
              Email:
            </label>
            <input
              className="w-full text-black px-3 py-2 border rounded-md focus:outline-none focus:border-green-500"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="message">
            Message:
          </label>
          <textarea
            className="w-full h-64 text-black px-3 py-2 border rounded-md focus:outline-none focus:border-green-500"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            placeholder="Message"
          />
        </div>
        <button
          className="w-full px-4 py-2 rounded-full bg-green-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          type="button"
          onClick={handleSubmit}
        >
          Send Message
        </button>
      </form>
    </div>


  );
};

export default ContactForm;
