import React from 'react';

export const agendas = [
  {
    "name": "Home"
  },
  {
    "name": "Skills"
  },
  {
    "name": "Experiences"
  },
  // {
  //   "name": "Education"
  // },
  {
    "name": "Projects"
  },
  {
    "name": "Articles"
  },
]

interface NavigationProps {
  showContactForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navigation: React.FC<NavigationProps> = ({showContactForm}) => {
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-2xl font-semibold ml-8"></div>
        <ul className="flex space-x-4">
          {agendas.map((agenda, index) => (
            <li key={index}>
              <button
                className="text-white hover:text-y-green text-xl"
                onClick={() => {
                  scrollToSection(agenda.name);
                  showContactForm(false);
                }}
              >
                {agenda.name}
              </button>
            </li>
          ))}
          <li>
            <button
              className="text-white hover:text-y-green text-xl"
              onClick={() => showContactForm(true)}
            >
              Contact
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;