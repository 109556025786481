import React from 'react';

const Skill: React.FC = () => {
  return (
    <section id='Skills'>
      <h2 className="text-4xl leading-tight font-semibold mb-4 border-b-4 border-y-green w-fit">Skills</h2>
      <div className="flex flex-wrap gap-2">
        <img
            alt="TypeScript"
            src="https://img.shields.io/badge/typescript-%23007ACC.svg?style=for-the-badge&logo=typescript&logoColor=white"
        />
        <img
            alt="React.js"
            src="https://img.shields.io/badge/react.js-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
        />
              <img
        alt="Next.js"
        src="https://img.shields.io/badge/Next.js-black?style=for-the-badge&logo=next.js&logoColor=white"
      />
            <img
        alt="Node.js"
        src="https://img.shields.io/badge/node.js-6DA55F?style=for-the-badge&logo=node.js&logoColor=white"
      />
            <img
        alt="TailwindCSS"
        src="https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white"
      />
      <img
        alt="JavaScript"
        src="https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E"
      />
    <img
        alt="Jest"
        src="https://img.shields.io/badge/-jest-%23C21325?style=for-the-badge&logo=jest&logoColor=white"
      />
      <img
        alt="Testing-Library"
        src="https://img.shields.io/badge/-TestingLibrary-%23E33332?style=for-the-badge&logo=testing-library&logoColor=white"
      />
    <img
        alt="Playwright"
        src="https://img.shields.io/badge/-Playwright-2EAD33?logo=playwright&logoColor=white&style=for-the-badge"
      />
      <img
        alt="React Native"
        src="https://img.shields.io/badge/react_native-%2320232a.svg?style=for-the-badge&logo=react&logoColor=%2361DAFB"
      />
      <img
        alt="Expo"
        src="https://img.shields.io/badge/expo-1C1E24?style=for-the-badge&logo=expo&logoColor=#D04A37"
      />
      <img
        alt="MySQL"
        src="https://img.shields.io/badge/mysql-%2300f.svg?style=for-the-badge&logo=mysql&logoColor=white"
      />
      <img
        alt="PHP"
        src="https://img.shields.io/badge/php-%23777BB4.svg?style=for-the-badge&logo=php&logoColor=white"
      />
      <img
        alt="jQuery"
        src="https://img.shields.io/badge/jquery-%230769AD.svg?style=for-the-badge&logo=jquery&logoColor=white"
      />
      <img
        alt="GitHub"
        src="https://img.shields.io/badge/-github-181717?logo=github&logoColor=white&style=for-the-badge"
      />
      <img
        alt="GitHub Actions"
        src="https://img.shields.io/badge/-github%20actions-2088FF?logo=github&logoColor=white&style=for-the-badge"
      />
      <img
        alt="Figma"
        src="https://img.shields.io/badge/-figma-F24E1E?logo=figma&logoColor=white&style=for-the-badge"
      />
    </div>
    </section>
  );
};

export default Skill;
