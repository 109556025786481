import React from 'react';
import Tarvelocal from '../images/travelocal.png';
import OFAC from '../images/ofac.png';
import SystemDesign from '../images/miro.png';
import Link from '../images/link.svg';


// Define a type for the project
const projects = [
    {
        image: OFAC,
        title: 'OFAC Search',
        description: 'OFAC Search is a web application crafted to conduct searches against the OFAC sanctions list. This tool assists organizations in determining whether any entities involved in a transaction are listed on watchlists curated by the Office of Foreign Assets Control (OFAC), a division of the U.S. Department of the Treasury.',
        thought: `
        ☆Areas of Focus</br>
        - A simple and user-friendly design</br>
        - Guiding users easily through validation processes</br>
        - Clarifying the source of data retrieval to prevent user confusion
        `,
        usedLanguage: [
            "React",
            "TypeScript",
            "Tailwind CSS",
            "API Integration",
          ],
        link: {
          Live: 'https://ofac-screening-app.vercel.app/',
          GitHub: 'https://github.com/YUIKO-NISHIMURA/ofac-screening-app',
        }
    },
    {
        image: SystemDesign,
        title: 'System design for an app provides loans to users',
        description: ' A system diagram for a fraud detection system that prevents an individual from receiving multiple loans.',
        thought: `
        ☆Areas of Focus<br>
        - Organized sections for each of the five user journeys to enhance readability.<br>
        - Provided detailed verification steps for easy visualization of the process.<br>
        - Minimized verification steps to ensure a swift detection speed, preventing prolonged user waiting.<br>
        - Implemented highly sensitive information in the database, reinforcing security measures.
        `,
        usedLanguage: [
            "Miro",
          ],
        link: {
          Miro: 'https://miro.com/app/board/uXjVNCWPG_o=/',
        }
    },
    {
        image: Tarvelocal,
        title: 'Travelocal',
        description: 'The concept is "Traveling as if Living," a website where locals share the shops and places they frequent with travelers.',
        thought: `
During my time as a student, having traveled to around 20 countries, I realized that there is an unique joy in exploring places not typically found in guidebooks. The places where locals frequent became a source of unforgettable memories in my travels.
To capture and share this excitement, I've created a platform where locals can post about their favorite shops and spots they regularly visit.`,
        usedLanguage: [
            "Ruby on Rails",
            "Bootstrap",
            "MySQL",
          ],
        link: {
          Live: 'http://travelocal.herokuapp.com/',
          GitHub: 'https://github.com/YUIKO-NISHIMURA/Tracelocal',
        }
    }
]

const Project: React.FC = () => {
  return (
    <section id='Projects'>
      <h2 className="text-4xl leading-tight font-semibold mb-8 border-b-4 border-y-green w-fit">Projects</h2>
      {projects && projects.map(({
        image,
        title,
        description,
        thought,
        usedLanguage,
        link
      }, index) => (
        <div className={`flex mb-6 ${index % 2 === 0 ? 'flex-row-reverse' : ''}`} key={title}>
        {/* Left Half - Project Details */}
        <div className="w-1/2 px-8 flex flex-col justify-between">
          <div>
            <h2 className="text-2xl font-bold mb-2">{title}</h2>
            <p className="mb-4"  dangerouslySetInnerHTML={{ __html: description }} />
            <p className="mb-4" dangerouslySetInnerHTML={{ __html: thought }} />
          </div>
          {/* Buttons for Live and GitHub */}
          <div className="flex space-x-4 mt-4 mb-11">
            {Object.entries(link).map(([key, link]) => (
              <a href={link} className="bg-[#C0C0C0] text-y-green font-semibold px-4 py-2 rounded flex" key={key} target='_blank' rel="noreferrer">
                {key}
                <img src={Link} alt='link' className="ml-2" />
              </a>
            ))}
          </div>
        </div>
          {/* Right Half - Image */}
          <div className="w-1/2 items-center">
            <img src={image} alt={title} className="w-full min-h-92 object-cover"/>
            <div className="flex flex-wrap gap-1 my-2">
              {usedLanguage.map((lang) => (
                <span key={lang} className="bg-y-green text-sm font-semibold p-1 rounded">{lang}</span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Project;

