import React, { useEffect, useState } from 'react';
import ScrumPic from '../images/scrum.svg'
import ReadmorePic from '../images/readme.svg'


export type ArticleType = {
  author: string;
  categories: string[];
  content: string;
  description: string;
  enclosure: Record<string, never>;
  guid: string;
  link: string;
  pubDate: string;
  thumbnail: string;
  title: string; 
};


const Article: React.FC = () => {
    const [articles, setArticles] = useState<ArticleType[]>([]);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await fetch(
            'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@yuzuko.1020'
            );

            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setArticles(data.items);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    }, []);

  return (
    <section id='Articles'>
      <h2 className="text-4xl leading-tight font-semibold mb-8 border-b-4 border-y-green w-fit">Articles</h2>
      <div className="flex flex-wrap -mx-4">
        {articles && articles.map((article) => (
          <div key={article.link} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 font-bold">
            <img
              src={ScrumPic}
              alt={article.title}
              className="w-full h-auto mb-4 rounded bg-white"
            />
            <h3 className="text-xl mb-2">{article.title}</h3>
            <a
              href={article.link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mt-2 bg-y-green py-2 px-4 rounded hover:bg-y-green-dark w-fit"
            >
              <span className="mr-2">Read More</span>
              <img src={ReadmorePic} alt="readmore" />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Article;
